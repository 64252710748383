import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostsList from "../components/postsList"

const TagTemplate = ({ location, pageContext, data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { tag } = pageContext
  return (
    <Layout location={location} title={siteTitle}>
      <div className="tag-container">
        <SEO title={`#${tag}`} />
        <h2>#{tag}</h2>
        <PostsList posts={data.allMarkdownRemark.nodes} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { fields: { tags: { in: [$tag] } } }
    ) {
      totalCount
      nodes {
        excerpt
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
        }
      }
    }
  }
`
// export const pageQuery = graphql`
//   query TagPage($tag: String) {
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt
//         fields {
//           slug
//           readingTime {
//             text
//           }
//         }
//         frontmatter {
//           date(formatString: "MMMM DD, YYYY")
//           title
//           description
//           tags
//         }
//       }
//     }
//     allMarkdownRemark(
//       limit: 1000
//       filter: { fields: { tags: { in: [$tag] } } }
//     ) {
//       totalCount
//       edges {
//         node {
//           fields {
//             slug
//             tags
//           }
//           excerpt
//           timeToRead
//           frontmatter {
//             title
//             date
//           }
//         }
//       }
//     }
//   }
// `

export default TagTemplate
